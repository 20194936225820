import classNames from "classnames";
import React, { ReactElement } from "react";
// We need to share a module to be aware of each other
import styles from "../LinkOverlay/LinkOverlay.module.scss";

export interface LinkOverlayContainerProps {
  children: ReactElement;
}

const LinkOverlayContainer = ({ children }: LinkOverlayContainerProps) => {
  return React.cloneElement(children, {
    className: classNames(children.props.className, styles.container)
  });
};

export default LinkOverlayContainer;
