import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import { Col, Row, Wrapper } from "../components/Grid";
import LandingPageCardGrid from "../components/LandingPageCardGrid/LandingPageCardGrid";
import Text from "../components/Text/Text";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext } from "../model/common";
import { SanityCaseStudiesPage } from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import styles from "./CaseStudiesPage.module.scss";

export const caseStudyLinkTargetFields = graphql`
  fragment CaseStudyLinkTargetFields on SanityCaseStudy {
    _id
    _type
    title
    slug {
      current
    }
    location {
      ... on SanityCountry {
        name
      }
      ... on SanityRegion {
        name
      }
    }
    _rawContent
    image {
      ...SanityImage
    }
  }
`;

export const pageQuery = graphql`
  query CaseStudiesPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityCaseStudiesPage(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      metaDescription
      introductionText
      grid {
        rows {
          items {
            ... on SanityLandingPageCardsFullWidth {
              _key
              _type
              text
              link {
                ...CaseStudyLinkTargetFields
              }
            }
            ... on SanityLandingPageCardsIconPair {
              _key
              _type
              leftLink {
                ...CaseStudyLinkTargetFields
              }
              rightLink {
                ...CaseStudyLinkTargetFields
              }
            }
            ... on SanityLandingPageCardsStackedIconPair {
              _key
              _type
              topLink {
                ...CaseStudyLinkTargetFields
              }
              bottomLink {
                ...CaseStudyLinkTargetFields
              }
            }
            ... on SanityLandingPageCardsVerticalImageCard {
              _key
              _type
              columns
              link {
                ...CaseStudyLinkTargetFields
              }
            }
          }
          _type
          _key
        }
      }
    }
  }
`;

const CaseStudiesPage = ({
  pageContext,
  data: { menus, page },
  location
}: CaseStudiesPageProps) => {
  const { title, introductionText } = page;
  return (
    <PageLayout
      siteArea={SiteArea.IMPACT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        type: "article"
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <Wrapper>
        <div style={{ marginBottom: "74px" }}>
          <Row className={styles.titleSection}>
            <Col xs={12} md={5} sm={8} offsetSm={1}>
              <Text variant="h1">{title}</Text>
              <Text variant="defaultLight">{introductionText}</Text>
            </Col>
          </Row>
          <LandingPageCardGrid node={page.grid} />
        </div>
      </Wrapper>
    </PageLayout>
  );
};

export default CaseStudiesPage;

interface CaseStudiesPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityCaseStudiesPage;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
