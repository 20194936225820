import classNames from "classnames";
import React from "react";
import { ReactNode } from "react";
import styles from "./AspectRatio.module.scss";

interface AspectRatioProps {
  /**
   * E.g. 16/9.
   */
  ratio: number;
  children: ReactNode;
  className?: string;
}

const AspectRatio = ({ ratio, children, className }: AspectRatioProps) => (
  <div
    className={classNames(styles.root, className)}
    style={{
      paddingBottom: 100 / ratio + "%"
    }}
  >
    {children}
  </div>
);

export default AspectRatio;
