import React from "react";
import HorizontalFullWidthImageCard from "../../components/HorizontalFullWidthImageCard/HorizontalFullWidthImageCard";
import IconCard from "../../components/IconCard/IconCard";
import {
  LinkTarget,
  SanityLandingPageCardGrid,
  SanityLandingPageCardGridRow
} from "../../model/landing-page-card-grid";
import { urlForDocument } from "../../urls";
import { textFromFirstBlockNoFallback } from "../../utils/blocks";
import CardContent from "../CardContent/CardContent";
import { Col, Row } from "../Grid";
import ImageCard from "../ImageCard/ImageCard";
import MicrobitEmoji, {
  MicrobitEmojiProps,
  emojiSequence
} from "../MicrobitEmoji/MicrobitEmoji";
import styles from "./LandingPageCardGrid.module.scss";

interface LandingPageCardGridProps {
  node: SanityLandingPageCardGrid;
}

const LandingPageCardGrid = ({ node: { rows } }: LandingPageCardGridProps) => {
  const emojis = emojiSequence();
  return (
    <div>
      {rows.map(row => (
        <LandingPageCardGridRow row={row} emojis={emojis} />
      ))}
    </div>
  );
};

interface LandingPageCardGridRowProps {
  row: SanityLandingPageCardGridRow;
  emojis: ReturnType<typeof emojiSequence>;
}

const LandingPageCardGridRow = ({
  row,
  emojis
}: LandingPageCardGridRowProps) => (
  <Row justify="center" className={styles.row}>
    {row.items.map(item => {
      switch (item._type) {
        case "landingPageCardsFullWidth":
          return (
            <Col xs={12} sm={10} md={10}>
              <HorizontalFullWidthImageCard image={item.link.image}>
                <CardContent
                  title={item.link.title}
                  tags={item.link.location.name}
                  titleVariant="h2"
                  text={
                    item.text ||
                    textFromFirstBlockNoFallback(item.link._rawContent)
                  }
                  textMaxLines={-1}
                  href={urlForDocument(item.link)}
                  readMore
                />
              </HorizontalFullWidthImageCard>
            </Col>
          );
        case "landingPageCardsIconPair":
          return (
            <React.Fragment>
              <Col xs={12} sm={5} md={5}>
                <EmojiIconCard link={item.leftLink} emojis={emojis} />
              </Col>
              <Col xs={12} sm={5} md={5}>
                <EmojiIconCard link={item.rightLink} emojis={emojis} />
              </Col>
            </React.Fragment>
          );
        case "landingPageCardsStackedIconPair":
          return (
            <React.Fragment>
              <Col xs={12} sm={5} md={5} className={styles.iconPairCol}>
                <EmojiIconCard link={item.topLink} emojis={emojis} />
                <EmojiIconCard link={item.bottomLink} emojis={emojis} />
              </Col>
            </React.Fragment>
          );
        case "landingPageCardsVerticalImageCard":
          return (
            <Col xs={12} sm={item.columns} md={item.columns}>
              <ImageCard
                className={styles.card}
                {...imageSizesForColumnWidth[item.columns]}
                image={item.link.image}
                href={urlForDocument(item.link)}
              >
                <CardContent
                  href={urlForDocument(item.link)}
                  title={item.link.title}
                  tags={item.link.location.name}
                  text={textFromFirstBlockNoFallback(item.link._rawContent)}
                  textMaxLines={textLinesForColumnWidth[item.columns]}
                  readMore
                />
              </ImageCard>
            </Col>
          );
        default:
          throw new Error();
      }
    })}
  </Row>
);

const imageSizesForColumnWidth: Record<
  number,
  { widths: number; aspectRatio: number }
> = {
  5: {
    widths: 564,
    aspectRatio: 564 / 236
  },
  4: {
    widths: 448,
    aspectRatio: 448 / 248
  },
  3: {
    widths: 332,
    aspectRatio: 332 / 247
  },
  2: {
    widths: 206,
    aspectRatio: 206 / 178
  }
};

const textLinesForColumnWidth: Record<number, number> = {
  5: 3,
  4: 2,
  3: 3,
  2: 4
};

interface EmojiIconCardProps {
  link: LinkTarget;
  emojis: ReturnType<typeof emojiSequence>;
}

const EmojiIconCard = ({ link, emojis }: EmojiIconCardProps) => (
  <IconCard
    icon={<MicrobitEmoji {...(emojis.next().value as MicrobitEmojiProps)} />}
  >
    <CardContent
      title={link.title}
      titleVariant="h3"
      href={urlForDocument(link)}
      tags={link.location.name}
      text={textFromFirstBlockNoFallback(link._rawContent)}
      readMore
    />
  </IconCard>
);

export default LandingPageCardGrid;
