import { FitMode } from "@sanity/image-url/lib/types/types";
import classnames from "classnames";
import React, { ImgHTMLAttributes, ReactNode } from "react";
import { SanityImage } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import AspectRatio from "../AspectRatio/AspectRatio";
import styles from "./Image.module.scss";

export const enum ImageRatios {
  Square = 1,
  Landscape_16_9 = 16 / 9,
  Landscape_4_3 = 4 / 3,
  Landscape_3_2 = 3 / 2
}

type CornerMode = "rounded" | "card";

/**
 * Desktop, tablet, mobile.
 */
export type BreakpointValues = [number, number, number] | number;

interface ImageProps {
  className?: string;
  image?: SanityImage;
  src?: string;
  alt?: string;
  children?: ReactNode;
  aspectRatio: ImageRatios;
  widths: BreakpointValues;
  fit?: FitMode;
  bg?: string;
  corners?: CornerMode;
  // Optional padding around the image, suitable for logos etc.
  padded?: boolean;
  ignoreImageParams?: boolean;
  loading?: ImgHTMLAttributes<HTMLImageElement>["loading"];
}

const buildSrc = (
  image: SanityImage,
  width: number,
  aspectRatio: ImageRatios,
  fit: FitMode,
  bg: string,
  ignoreImageParams: boolean
) => {
  const height = Math.round(width / aspectRatio);
  let builder = imageUrlBuilder
    .image(image)
    .quality(90)
    .width(width)
    .height(height)
    .fit(fit)
    .bg(bg);
  if (ignoreImageParams) {
    builder = builder.ignoreImageParams();
  }
  return builder.url()!;
};

const buildSrcSet = (
  image: SanityImage,
  aspectRatio: number,
  fit: FitMode,
  bg: string,
  ignoreImageParams: boolean,
  widths: BreakpointValues
) => {
  if (typeof widths === "number") {
    widths = [widths, widths, widths];
  }
  const sizes = Array.from(new Set([...widths, ...widths.map(s => s * 2)]));
  const srcs = sizes.map(width =>
    buildSrc(image, width, aspectRatio, fit, bg, ignoreImageParams)
  );
  return {
    srcSet: srcs.map((src, index) => `${src} ${sizes[index]}w`).join(", "),
    sizes: `(min-width: 1200px) ${formatSize(
      widths[0]
    )}, (min-width: 992px) ${formatSize(widths[1])}, ${formatSize(widths[2])}`
  };
};

const formatSize = (v: string | number) => {
  return typeof v === "number" ? `${v}px` : v;
};

const isImageType = (image: SanityImage, type: string) =>
  image?._id?.endsWith(type) ||
  (image?.asset as any)?._ref?.endsWith(type) ||
  image?.asset?.url?.endsWith(type);

// Skip missing SVG images when using the translation preview dataset.
export const shouldSkipMissingImageAsset = (
  image: SanityImage | undefined
): boolean => {
  if (
    process.env.GATSBY_STAGE === "TRANSLATION_PREVIEW" &&
    image &&
    !image?.asset
  ) {
    return true;
  }
  return false;
};

const Image = ({
  image,
  alt,
  src,
  bg = "fff",
  fit = "min",
  aspectRatio,
  ignoreImageParams,
  corners,
  padded,
  widths,
  loading = "lazy",
  className
}: ImageProps) => {
  if (shouldSkipMissingImageAsset(image)) {
    return null;
  }
  if ((image && src) || (!image && !src)) {
    throw new Error("Supply image or src not both");
  }
  alt = image?.alt ?? alt ?? "";
  // Our GIF images are animations which Sanity doesn't resize.
  // We wrap them in an aspect ratio box so they look OK.
  const isGif = image && isImageType(image, "gif");
  const isSvg = image && isImageType(image, "svg");
  const isNonResizabe = isGif || isSvg;
  let srcSetDetails: {
    srcSet: string | undefined;
    sizes: string | undefined;
  } = { srcSet: undefined, sizes: undefined };
  if (image) {
    src = buildSrc(
      image,
      Array.isArray(widths) ? widths[0] : widths,
      aspectRatio,
      fit,
      bg,
      ignoreImageParams ?? false
    );
    srcSetDetails = buildSrcSet(
      image,
      aspectRatio,
      fit,
      bg,
      ignoreImageParams ?? false,
      widths
    );
  }
  const img = (
    <img
      className={classnames(
        styles.root,
        isGif && styles.gif,
        padded && styles.padded,
        !padded && !isGif && styles.other,
        corners && styles[corners],
        className
      )}
      loading={loading}
      alt={alt}
      src={src}
      {...srcSetDetails}
      style={{
        aspectRatio: !isGif ? aspectRatio.toString() : undefined
      }}
    />
  );
  if (isNonResizabe) {
    return (
      <AspectRatio ratio={aspectRatio} className={styles.nonResizable}>
        {img}
      </AspectRatio>
    );
  }
  return img;
};

export default Image;
