import { FitMode } from "@sanity/image-url/lib/types/types";
import classnames from "classnames";
import React, { ReactNode } from "react";
import { SanityImage } from "../../model/common";
import Card from "../Card/Card";
import Image, { BreakpointValues, ImageRatios } from "../Image/Image";
import LinkOverlay from "../LinkOverlay/LinkOverlay";
import LinkOverlayContainer from "../LinkOverlayContainer/LinkOverlayContainer";
import Text from "../Text/Text";
import styles from "./ImageCard.module.scss";

interface ImageCardProps {
  className?: string;
  image?: SanityImage;
  src?: string;
  alt?: string;
  children?: ReactNode;
  aspectRatio: ImageRatios;
  widths: BreakpointValues;
  fit?: FitMode;
  bg?: string;
  // Optional padding around the image, suitable for logos etc.
  padded?: boolean;
  ignoreImageParams?: boolean;
  // Used to overlay text on top of image. Pass a component for a custom overlay.
  // Used for Events cards with a date overlay and then otherwise just on the primary landing page via CMS config.
  overlay?: string | ReactNode;
  topContent?: ReactNode;
  href?: string;
}

const ImageCard = ({
  className,
  children,
  overlay,
  topContent,
  href,
  ...rest
}: ImageCardProps) => {
  return (
    <LinkOverlayContainer>
      <Card className={classnames(styles.root, className)}>
        {topContent}
        <div className={styles.container}>
          <Image {...rest} corners="card" />
          {typeof overlay === "string" ? (
            href ? (
              <LinkOverlay to={href} className={styles.overlay}>
                <Text variant="h4">{overlay}</Text>
              </LinkOverlay>
            ) : (
              <div className={styles.overlay}>{overlay}</div>
            )
          ) : (
            overlay
          )}
        </div>
        <div className={styles.content}>{children}</div>
      </Card>
    </LinkOverlayContainer>
  );
};

export default ImageCard;
